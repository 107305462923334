<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
             <div class="item"> 
                    <li class=" a"><router-link to="/index/cw">数据概况</router-link></li>
                    <li class=" a"><router-link to="/index/cwmx">财务明细</router-link></li>
                        <li class="active a">分成设置</li>
                      <li class=" a"><router-link to="/index/fp">发票管理</router-link></li>
                </div>
                 <div  class="item">  
                    <li class="a"><router-link to="/index6/xiaoxi66">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i>消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">总台管理<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                             <el-dialog class="ik" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                              <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
                           
                              <el-dialog title="分成比例" style="text-align:center" :visible.sync="dialogFormVisible22">
                                    <el-form :model="from2">
                                        <el-form-item label="代理商比例(普)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone" autocomplete="off" >  <el-button slot="append" @click="xg1()">修改</el-button></el-input>
                                        </el-form-item>
                                          <el-form-item label="平台比例比例(普)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone1" autocomplete="off" > <el-button slot="append" @click="xg2()">修改</el-button></el-input>
                                        </el-form-item>
                                          <el-form-item label="市场总监比例(普)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone2" autocomplete="off" > <el-button slot="append" @click="xg3()">修改</el-button></el-input>
                                        </el-form-item>
                                         <el-form-item label="技术总监比例(普)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone3" autocomplete="off"  > <el-button slot="append" @click="xg4()">修改</el-button></el-input>
                                        </el-form-item>
                                         <el-form-item label="财务总监比例(普)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone9" autocomplete="off"  > <el-button slot="append" @click="xg9()">修改</el-button></el-input>
                                        </el-form-item>


                                          <el-form-item label="代理商比例(附)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone4" autocomplete="off" >  <el-button slot="append" @click="xg5()">修改</el-button></el-input>
                                        </el-form-item>
                                          <el-form-item label="平台比例比例(附)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone5" autocomplete="off" > <el-button slot="append" @click="xg6()">修改</el-button></el-input>
                                        </el-form-item>
                                          <el-form-item label="市场总监比例(附)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone6" autocomplete="off" > <el-button slot="append" @click="xg7()">修改</el-button></el-input>
                                        </el-form-item>
                                         <el-form-item label="技术总监比例(附)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone7" autocomplete="off"  > <el-button slot="append" @click="xg8()">修改</el-button></el-input>
                                        </el-form-item>
                                       
                                          <el-form-item label="财务总监比例(附)" :label-width="formLabelWidths">
                                        <el-input v-model="from2.phone10" autocomplete="off"  > <el-button slot="append" @click="xg10()">修改</el-button></el-input>
                                        </el-form-item>
                                    </el-form>
                    
                        </el-dialog>  
             </ul>
        </div>
         <div class="search"></div>
            <div class="content" >
               
                    <p style="padding:30px 10px;background:#fff;" >平台和代理商分成列表</p>
                    
                          <el-table
                    :border="true"
                    :data="datal1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%;">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="代理商"
                   >
                    </el-table-column>
                    <el-table-column
                        prop="normal" 
                    label="常规订单金额"
                        :show-overflow-tooltip="true" 
                  >
                    </el-table-column>

                   

                    <el-table-column
                    label="附属订单金额"
                    prop="extend" 
                    
                  >
                    
                    </el-table-column>
                    

                    
                    <el-table-column
                        prop="n_rate_pt" 
                    label="平台分成比例(普)%" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>

                        <el-table-column
                        prop="n_rate_dls" 
                    label="代理商分成比例(普)%"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                       <el-table-column
                        prop="n_rate_cwzj" 
                    label="财务分成比例(普)%"
                        :show-overflow-tooltip="true" 
                  >
                    </el-table-column>
                      <el-table-column
                        prop="n_rate_sczj" 
                    label="市场总监(普)%"
                        :show-overflow-tooltip="true" 
                     
                    >
                    </el-table-column>
                    
            <el-table-column
                        prop="n_rate_jszj" 
                    label="技术总监(普)%"
                        :show-overflow-tooltip="true" 
                     
                    >
                  
                    </el-table-column>



  <el-table-column
                        prop="e_rate_pt" 
                    label="平台分成比例(附)%" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>
   <el-table-column
                        prop="e_rate_cwzj" 
                    label="财务分成比例(附属)%"
                        :show-overflow-tooltip="true" 
                  >
                    </el-table-column>
                        <el-table-column
                        prop="e_rate_dls" 
                    label="代理商分成比例(附)%"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                      <el-table-column
                        prop="e_rate_sczj" 
                    label="市场总监(附)%"
                        :show-overflow-tooltip="true" 
                     
                    >
                    </el-table-column>
                    
            <el-table-column
                        prop="e_rate_jszj" 
                    label="技术总监(附)%"
                        :show-overflow-tooltip="true" 
                     
                    >
                  
                    </el-table-column>

                  
                </el-table>
            </div>
  </div>
</template>

<script>
export default {
 data(){
        return{
            data:[],
            datal1:[],
            datall:[],
                dialogFormVisible:false,
                    dialogFormVisible22:false,

                    from2:{
                         phone:'',
          phone1: '',
            phone2: '', phone3: '',
               phone4:'',
          phone5: '',
            phone6: '', phone7: '',
            phone10:'',
            phone9:''
                    },
                form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        index:'',
          formLabelWidths: '120px',
        info:[],
infos:[],
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                    jobnumber:'',
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
       methods:{
           xg1(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "n_rate_dls",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },
           xg2(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "n_rate_pt",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone1
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },

 xg9(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "n_rate_cwzj",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone9
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },
            xg10(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "e_rate_cwzj",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone10
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },


            xg3(){
                   this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "n_rate_sczj",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone2
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })
            },
            
           xg4(){
                   this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "n_rate_jszj",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone3
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })
            },



  xg5(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "e_rate_dls",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone4
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },



  xg6(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "e_rate_pt",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone5
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },



            xg7(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "e_rate_sczj",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone6
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },

  xg8(){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                        },
                        data:{
                              "field": "e_rate_jszj",
                            "jobnumber": this.jobnumber,
                            "value": this.from2.phone7
                        }
                     
                        }) .then(res=>{
                             if(res.data.code==0){
                              this.dialogFormVisible22 = false;
                                       this.$message({
            type: 'success',
            message: '修改成功'
          });     
                                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data
                                 for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                        })
                        }else{
                            this.dialogFormVisible22 =false
                                  this.$message({
            type: 'info',
            message: res.data.msg
          });     
                        }
    
                        })

           },


             tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a11(){
          this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:this.info,
                        }) .then(res=>{
                            this.dialogFormVisible = false
                              this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                        })
        .catch(function(){
           
        })  
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
           handleClick(){},
                  handleEdit(index, row){
                 
                      this.dialogFormVisible22 = true
                        this.index = index
                        this.jobnumber = row.jobnumber
                              this.from2.phone=row.n_rate_dls
                  this.from2.phone1 = row.n_rate_pt
                  this.from2.phone2 =row.n_rate_sczj
                   this.from2.phone3 = row.n_rate_jszj


                      this.from2.phone4=row.e_rate_dls
                  this.from2.phone5 = row.e_rate_pt
                  this.from2.phone6 =row.e_rate_sczj
                   this.from2.phone7 = row.e_rate_jszj
                     this.from2.phone9 = row.n_rate_cwzj
                       this.from2.phone10 = row.e_rate_cwzj
                    
        },
        go(){
             var  data = this.datal1[this.index]
             console.log(data)
             console.log(data)
                this.data = this.datal1[this.index]
                data.e_rate_dls=this.from2.phone
                data.e_rate_pt = this.from2.phone1
                data.e_rate_sczj =this.from2.phone2
                 data.e_rate_jszj = this.from2.phone3
                   this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_change",
                        params: {   
                        accessToken : localStorage.getItem('token')
                        },
                     data:data
                     
                        }) .then(res=>{
                            if(res.data.code == 0 ){
                                    this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                     this.dialogFormVisible22=false
                     this.$router.go(0)
                            }else{
                                       this.$message({
                   message:'修改失败',
                  type: 'error'
                     });  this.dialogFormVisible22=false
                            }

                        }) .catch(function(){
           
        })
        }
       },
        mounted(){
 






  this.$axios({
                        method: "post",
                        url: "/s/backend/api/fcbl_list",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                         'page':1,
                          'limit':20
                        },
                     
                        }) .then(res=>{
                                this.datal1= res.data.data

                                for(var i=0;i<res.data.data.length;i++){
                                    res.data.data[i].extend =  res.data.data[i].extend/100
                                     res.data.data[i].normal =  res.data.data[i].normal/100
                                }
                               
                        })
    


    
         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>


    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }


/deep/ .el-dialog__wrapper{
 margin-top:-5vh
}

/deep/.ik  .el-dialog{
        width: 25%;
 margin-top:-5vh;
    float: right;
  
}

.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>